import React from "react";

export const PrinterIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M19 8H5a3 3 0 0 0-3 3v6h4v4h12v-4h4v-6a3 3 0 0 0-3-3zm-3 11H8v-5h8v5zm3-7c-.6 0-1-.5-1-1s.5-1 1-1 1 .5 1 1-.5 1-1 1zm-1-9H6v4h12V3z"
		/>
	</svg>
);

export const VerifiedIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="m23 12-2.4-2.8.3-3.7-3.6-.8-1.9-3.2L12 3 8.6 1.5 6.7 4.7l-3.6.8.3 3.7L1 12l2.4 2.8-.3 3.7 3.6.8 1.9 3.2L12 21l3.4 1.5 1.9-3.2 3.6-.8-.3-3.7L23 12zM9.4 16 7 13.6a1 1 0 0 1 0-1.4 1 1 0 0 1 1.5 0l1.6 1.5 5.2-5.1a1 1 0 0 1 1.4 0c.4.5.4 1 0 1.5l-5.9 6a1 1 0 0 1-1.4 0z"
		/>
	</svg>
);

export const VerifiedIconOutline = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="m23 12-2.4-2.8.3-3.7-3.6-.8-1.9-3.2L12 3 8.6 1.5 6.7 4.7l-3.6.8.3 3.7L1 12l2.4 2.8-.3 3.7 3.6.8 1.9 3.2L12 21l3.4 1.5 1.9-3.2 3.6-.8-.3-3.7L23 12zm-4 1.5-.5.6v.9l.3 2-2 .4-.8.1-.4.8-1 1.7-1.8-.8-.8-.3-.8.3-1.8.8-1-1.7-.4-.8-.8-.1-2-.5.2-2 .1-.8-.5-.6L3.7 12 5 10.5l.5-.6V9l-.3-2 2-.4.8-.1.4-.8 1-1.7 1.8.8.8.3.8-.3 1.8-.8 1 1.7.4.7.8.2 2 .5-.2 2-.1.8.5.6 1.3 1.5-1.3 1.5z"
		/>
		<path
			className={"fill-current"}
			d="m10.1 13.8-2.3-2.4-1.5 1.5 3.8 3.8 7.3-7.3-1.5-1.5z"
		/>
	</svg>
);

export const AddShieldOutline = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M6 11V6.5L12 4l6 2.3V10a7 7 0 0 1 2 .6V5l-8-3-8 3v6c0 5.1 3.4 9.8 8 11a7 7 0 0 1-1.7-2.9 9.6 9.6 0 0 1-4.3-8z"
		/>
		<path
			className={"fill-current"}
			d="M17 12a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm3 5.5h-2.5V20h-1v-2.5H14v-1h2.5V14h1v2.5H20v1z"
		/>
	</svg>
);

export const PersonAddIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M15 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4z"
		/>
	</svg>
);

export const PersonAddIconOutline = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M15 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 8c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4zm-6 4c.2-.7 3.3-2 6-2s5.8 1.3 6 2H9zm-3-3v-3h3v-2H6V7H4v3H1v2h3v3z"
		/>
	</svg>
);

export const PhoneCallIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M20 15.5c-1.3 0-2.5-.2-3.6-.6a1 1 0 0 0-1 .3l-2.2 2.2a15 15 0 0 1-6.6-6.6l2.2-2.2c.3-.3.4-.7.3-1-.4-1.1-.6-2.3-.6-3.6 0-.6-.5-1-1-1H4a1 1 0 0 0-1 1 17 17 0 0 0 17 17c.6 0 1-.5 1-1v-3.5c0-.6-.5-1-1-1zM19 12h2c0-5-4-9-9-9v2a7 7 0 0 1 7 7zm-4 0h2a5 5 0 0 0-5-5v2a3 3 0 0 1 3 3z"
		/>
	</svg>
);

export const ConversationIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M15 4v7H5.2l-.6.6-.6.6V4h11m1-2H3a1 1 0 0 0-1 1v14l4-4h10c.6 0 1-.5 1-1V3c0-.6-.5-1-1-1zm5 4h-2v9H6v2c0 .6.5 1 1 1h11l4 4V7c0-.6-.5-1-1-1z"
		/>
	</svg>
);

export const PersonGearIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M4 18v-.7c0-.3.2-.6.4-.8A11 11 0 0 1 10 15l.7-2H10c-2.4 0-4.7.7-6.6 1.8A3 3 0 0 0 2 17.4V20h9.3c-.5-.6-.8-1.3-1-2H4zm6-6a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm10.8 10-.1-.6 1.1-1-1-1.8-1.4.5c-.3-.3-.7-.5-1.1-.6L18 11h-2l-.3 1.5c-.4.1-.8.4-1 .6l-1.5-.5-1 1.8 1.1 1v1.2l-1.1 1 1 1.8 1.4-.5c.3.3.7.5 1.1.6L16 21h2l.3-1.5c.4-.1.8-.3 1-.6l1.5.5 1-1.8-1.1-1V16zM17 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
		/>
	</svg>
);

export const OpenIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-7h-2v7zM14 3v2h3.6l-9.8 9.8 1.4 1.4L19 6.4V10h2V3h-7z"
		/>
	</svg>
);

export const InfoIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"
		/>
	</svg>
);

export const FilledInfoIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
		/>
	</svg>
);

export const CircledQuestionMarkIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M11 18h2v-2h-2v2zm1-16a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm0-14a4 4 0 0 0-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.8-3 5h2c0-2.3 3-2.5 3-5a4 4 0 0 0-4-4z"
		/>
	</svg>
);

export const LockOpenIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6A5 5 0 0 0 7 6h1.9a3.1 3.1 0 0 1 6.2 0v2H6a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2zm0 12H6V10h12v10z"
		/>
	</svg>
);

export const LockClosedIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M18 8h-1V6A5 5 0 0 0 7 6v2H6a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6a3.1 3.1 0 0 1 6.2 0v2z"
		/>
	</svg>
);

export const ContentCopyIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M16 1H4a2 2 0 0 0-2 2v14h2V3h12V1zm3 4H8a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2zm0 16H8V7h11v14z"
		/>
	</svg>
);

export const CheckmarkIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
		/>
	</svg>
);
export const CheckmarkDoubleIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="m18 7-1.4-1.4-6.4 6.3 1.5 1.4L18 7zm4.2-1.4L11.7 16.2 7.5 12 6 13.4l5.6 5.6 12-12-1.5-1.4zM.4 13.4 6 19l1.4-1.4L1.8 12 .4 13.4z"
		/>
	</svg>
);

export const FullscreenIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
		/>
	</svg>
);

export const CloseFullscreenIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="m22 3.4-5.3 5.3L20 12h-8V4l3.3 3.3L20.6 2 22 3.4zM3.4 22l5.3-5.3L12 20v-8H4l3.3 3.3L2 20.6 3.4 22z"
		/>
	</svg>
);

export const PhoneIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M17 1H7a2 2 0 0 0-2 2v18c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm0 18H7V5h10v14z"
		/>
	</svg>
);

export const MailLetterIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M22 6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V6zm-2 0-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"
		/>
	</svg>
);

export const PrinterOutlineIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M19 8h-1V3H6v5H5a3 3 0 0 0-3 3v6h4v4h12v-4h4v-6a3 3 0 0 0-3-3zM8 5h8v3H8V5zm8 12v2H8v-4h8v2zm2-2v-2H6v2H4v-4c0-.6.5-1 1-1h14c.6 0 1 .5 1 1v4h-2z"
		/>
		<circle className={"fill-current"} cx="18" cy="11.5" r="1" />
	</svg>
);

export const SaveUpIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M16 18v2H8v-2h8zM11 8v8h2V8h3l-4-4-4 4h3z"
		/>
	</svg>
);

export const CloudSyncIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path
			className={"fill-current"}
			d="M21.5 15a3.5 3.5 0 0 0-6.7-1 3 3 0 0 0 .2 6h6.5a2.5 2.5 0 0 0 0-5zM10 4.3v2a6 6 0 0 0-2 10.1V14h2v6H4v-2h2.7A8 8 0 0 1 10 4.3zM20 6h-2.7a8 8 0 0 1 2.6 5h-2A6 6 0 0 0 16 7.6V10h-2V4h6v2z"
		/>
	</svg>
);

export const SparkleIcon = () => (
	<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path
			className={"fill-current"}
			d="m19 1-1.3 2.8L15 5l2.7 1.3L19 9l1.3-2.7L23 5l-2.8-1.3M9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5M19 15l-1.3 2.7L15 19l2.7 1.3L19 23l1.3-2.8L23 19l-2.8-1.3"
		/>
	</svg>
);
